export const userInputs = [
    // {
    //   id: "username",
    //   label: "Username",
    //   type: "text",
    //   placeholder: "john_doe",
    // },
    {
      id: "displayName",
      label: "Name and surname",
      type: "text",
      placeholder: "John Doe",
    },
    {
      id: "email",
      label: "Email",
      type: "mail",
      placeholder: "john_doe@gmail.com",
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "+91 234 567 8900",
    },
    {
      id: "password",
      label: "Password",
      type: "password",
    },
    // {
    //   id: "address",
    //   label: "Address",
    //   type: "text",
    //   placeholder: "Elton St. 216 NewYork",
    // },
    {
      id: "seminar",
      label: "Course",
      type: "BankNifty Master course",
      placeholder: "BankNifty Master course",
      disabled : true,
      readonly : true,
    },

    // {
    //   id: "registrationDate",
    //   label: "registrationDate",
    //   type: "text",
    //   placeholder: "dd/mm/yyyy",
    // },

    // {
    //   id: "subscriptionEndDate",
    //   label: "subscriptionEndDate",
    //   type: "text",
    //   placeholder: "dd/mm/yyyy",
    // },
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  