import { Space, Spin } from "antd";
import { useEffect, useReducer, useRef, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import "./Home.scss";
import { app, database } from "../../firebase";

import { ref, onValue, get, child } from "firebase/database";
import { Column } from "@ant-design/plots";
import { ResponsiveContainer } from "recharts";
import Charts from "../../components/chart/Chart";

//import Chart from "react-apexcharts";

const HomeOIData = () => {
  const [todoList, setTodoList] = useState({});
  const [getNiftyOpening,setNiftyOpening] = useState({})
  const todoRef = database;
  const refrenceForBBCDB = ref(todoRef, "/PCRDB/OIdata");
  const refrenceForNifty = ref(todoRef, "/PCRDB/NiftyOpening");

  useEffect(() => {
    console.log("entered HomeOIData");
    try {
      //Firebase code
      const niftyOpen = onValue(refrenceForNifty,(snapshot) =>{

        const getData = snapshot.val();
        setNiftyOpening(getData) 
       // console.log('getDate: '+ getData)

      })
      const listener = onValue(refrenceForBBCDB, (snapshot) => {
        //const data = snapshot.val();
        const todos = snapshot.val();
        //console.log(todos);

        try {
          var todo = [];

          for (let id in todos) {
            //    console.log(todos[id].strikePrice);

            var add = inRange(
              parseInt(todos[id].strikePrice),
              parseInt(16800),
              parseInt(17200)
            );

            // if (add) {
            //   console.log(todos[id].strikePrice);
            //   chartCategory.push(todos[id].strikePrice);
            //   ceOI.push(todos[id].callOI);
            //   peOI.push(todos[id].putOI);
            // }

            todo.push(todos[id]);
          }
          // console.log(chartCategory);
          // console.log(ceOI);
          // console.log(peOI);

           setTodoList(todo);
          // console.log("todolist: "+ todoList)
        } catch (err) {
          console.log(err);
        }

        console.log("*******************************");
      });

      return listener;
    } catch (err) {
      console.log("use effect error: " + err);
      return null;
    }

    // return () => {
    //   listener();
    // };
  }, []);

  

  // return true if in range, otherwise false
  function inRange(x, min, max) {
    //min = min === null ? global.NIFTY_MIN_RANGE : min;
    //max = max === null ? global.NIFTY_MAX_RANGE : max;
    return (x - min) * (x - max) <= 0;
  }

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
       {
        todoList && getNiftyOpening && todoList.length>0? 
         <Charts  
        aspect = "80%"
         title = "tets"
         todo = {todoList}
         open = {getNiftyOpening}
        /> 
        :
         <p>waiting to get data</p>
       }
      </div>
    </div>
  );
};

export default HomeOIData;
