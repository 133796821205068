import Home from "./pages/Home/Home";
import HomeBNF from "./pages/banknifty/HomeBNF";
import HomeFinNifty from "./pages/finnifty/HomeFinNifty";
import HomeOIData from "./pages/oi_data/Home";
import BANKNIFTY_OPTIONS_SELLING from "./pages/banknifty_options_sell/Home_BNF_SELL";
import FINNIFTY_OPTIONS_SELLING from "./pages/finnifty_option_sell/Home_FinNifty_SELL"
import NIFTY_OPTIONS_SELLING from "./pages/nifty_options_sell/Home";
import Login from "./pages/login/Login";
import Positional from "./pages/Positional/Home";
import Crude from "./pages/Crude/Home";
import HomeEquity from "./pages/equity/HomeEquity";
import HomeEquitySell from "./pages/equity_option_selling/HomeEquitySell"
import CRUDE_OPTIONS_SELLING from "./pages/CRUDE_OPTIONS_SELLING/Home";
// import List from "./pages/list/List";
// import Single from "./pages/single/Single";
// import New from "./pages/new/New";
import Register from "./pages/register/Register";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    console.log("RequireAuth: " + children);
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />

            <Route
              path="banknifty"
              element={
                <RequireAuth>
                  <HomeBNF />
                </RequireAuth>
              }
            />


            <Route
              path="finnifty"
              element={
                <RequireAuth>
                  <HomeFinNifty />
                </RequireAuth>
              }
            />

            <Route
              path="nifty-option-selling"
              element={
                <RequireAuth>
                  <NIFTY_OPTIONS_SELLING />
                </RequireAuth>
              }
            />

            <Route
              path="banknifty-option-selling"
              element={
                <RequireAuth>
                  <BANKNIFTY_OPTIONS_SELLING />
                </RequireAuth>
              }
            />


<Route
              path="finnifty-option-selling"
              element={
                <RequireAuth>
                  <FINNIFTY_OPTIONS_SELLING />
                </RequireAuth>
              }
            />


<Route
              path="equity-option-buying"
              element={
                <RequireAuth>
                  <HomeEquity />
                </RequireAuth>
              }
            />


<Route
              path="equity-option-selling"
              element={
                <RequireAuth>
                  <HomeEquitySell />
                </RequireAuth>
              }
            />

            <Route
              path="oi_data"
              element={
                <RequireAuth>
                  <HomeOIData />
                </RequireAuth>
              }
            />

            <Route
              path="crude-option-buying"
              element={
                <RequireAuth>
                  <Crude />
                </RequireAuth>
              }
            />

            <Route
              path="crude-option-selling"
              element={
                <RequireAuth>
                  <CRUDE_OPTIONS_SELLING />
                </RequireAuth>
              }
            />

            <Route
              path="positional"
              element={
                <RequireAuth>
                  <Positional />
                </RequireAuth>
              }
            />


        

            <Route
              path="register"
              element={
                <Register inputs={userInputs} title="User registration" />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
