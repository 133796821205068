import { Select, Space, Switch } from "antd";

const { Option } = Select;

const Toolbar = ({
  showCe,
  setData,
  selectedMonthYearCombo,
  possibleMonthYearCombo,
  handleMonthYearComboChange,
  CE,
  PE
}) => {


  // var callGenerated = ({ signals }) => { 
  //   const data = signals.filter(({ instrumentType }) =>
  //      instrumentType === "CE" 
  //   ).length;
  
  //   var putGenerated = ({ signals }) => { 
  //     const data = signals.filter(({ instrumentType }) =>
  //        instrumentType === "PE" 
  //     ).length;


  return (



<Space align="end" style={{ width: "100%", justifyContent: "space-around" }}>
<div >
        <p>TOTAL CE</p> <div style={{ textAlign: "center",color: "green" }}><b>{CE}</b></div>
  </div>


  <div >
        <p>TOTAL PE</p> <div style={{ textAlign: "center",color: "RED" }}><b>{PE}</b></div>
  </div>
<div style={{ marginRight: "20px" }}>
        <p>CE/PE</p>
        <Switch
          style={{ backgroundColor: "#1890ff" }}
          onChange={(val) => setData({ type: "TOGGLE_CE/PE" })}
          checked={showCe}
          checkedChildren="CE"
          unCheckedChildren="PE"
        />
      </div>

      
      
      <div>
        <p>Year-Month</p>
        <Select
          value={`${selectedMonthYearCombo.year}-${selectedMonthYearCombo.month}`}
          onChange={handleMonthYearComboChange}>
          {possibleMonthYearCombo.map(({ year, month }) => (
            <Option
              key={`${year}-${month}`}
              value={`${year}-${month}`}>{`${year}-${month}`}</Option>
          ))}
        </Select>
      </div>
    
    </Space>
  
   
  );
};

export default Toolbar;