import React, { useRef, useState } from 'react';
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import Highlighter from 'react-highlight-words';



const SignalsTable = ({ signals, showCe }) => {

 

  const data = signals.filter(({ instrumentType }) =>
    showCe ? instrumentType === "CE" : instrumentType === "PE"
  );


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1890ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});



const columns = [
  {
    title: "Script",
    dataIndex: "tradingSymbol",
    key: "tradingSymbol",
    render: function (text) {
      return <p>{text.match(/[a-z]+|[^a-z]+/gi).join(" ")}</p>;
    },
    sorter: {
      compare: (a, b) => (a.tradingSymbol < b.tradingSymbol ? true : false),
      multiple: 1,
    },
    ...getColumnSearchProps('tradingSymbol'),
  },
  {
    title: "Signal",
    dataIndex: "signal",
    key: "signal",
  },
  {
    title: "Signal Type",
    dataIndex: "signalType",
    key: "signalType",
    sorter: {
      compare: (a, b) => (a.signalType < b.signalType ? true : false),
      multiple: 1,
    },
  },
   {
    title: "Open",
    dataIndex: "current_open",
    key: "current_open",
  },
  {
    title: "High",
    dataIndex: "current_high",
    key: "current_high",
  },
  {
    title: "Low",
    dataIndex: "current_low",
    key: "current_low",
  },
  {
    title: "Prev close",
    dataIndex: "previous_close",
    key: "previous_close",
  },
  {
    title: "LTP",
    dataIndex: "last_price",
    key: "last_price",
    sorter: {
      compare: (a, b) => a.last_price - b.last_price,
      multiple: 2,
    },
  },
  {
    title: "% Change",
    dataIndex: "change",
    key: "change",
    sorter: {
      compare: (a, b) => Number(a.change) - Number(b.change),
      multiple: 1,
    },
  },
  {
    title: "Signal Generated Date",
    dataIndex: "signalGeneratedDate",
    key: "signalGeneratedDate",
    render: (text) => <p>{moment(new Date(text)).format("hh:mm:ss A")}</p>,
    sorter: {
      compare: (a, b) => a.signalGeneratedDate - b.signalGeneratedDate,
      multiple: 1,
    },
  },
];


  //console.log({ data });

  return (
    <div style={{ margin: "10px auto", width: "90vw" }}>
      <Table
        rowKey="tradingSymbol"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default SignalsTable;