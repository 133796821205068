import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase, ref, set } from "firebase/database";
import { getAnalytics, setUserProperties } from "firebase/analytics";

// import firebase from 'firebase/compat/app';
// import "firebase/compat/database";
console.log("firebase:" +process.env.REACT_APP_SECONDARY_API_KEY)


const firebaseConfig = {
  
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
   
  };


const secondaryAppConfig = {
  


  apiKey: process.env.REACT_APP_SECONDARY_API_KEY,
  authDomain: process.env.REACT_APP_SECONDARY_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_SECONDARY_DATABASE_URL,
  projectId: process.env.REACT_APP_SECONDARY_PROJECT_ID,
  storageBucket: process.env.REACT_APP_SECONDARY_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SECONDARY_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_SECONDARY_APP_ID,
  measurementId: process.env.REACT_APP_SECONDARY_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const database = getDatabase(app);
export const analytics = getAnalytics();



const secondaryApp = initializeApp(secondaryAppConfig, "secondary");
export const secondaryDatabase = getDatabase(secondaryApp);


export default app;