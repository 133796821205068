import { Table } from "antd";
import moment from "moment";

const columns = [
  {
    title: "Script",
    dataIndex: "scriptname",
    key: "scriptname",
    render: function (text) {
      return <p>{text.match(/[a-z]+|[^a-z]+/gi).join(" ")}</p>;
    },
    sorter: {
      compare: (a, b) => (a.tradingSymbol < b.tradingSymbol ? true : false),
      multiple: 2,
    },
  },
  {
    title: "Signal",
    dataIndex: "signal",
    key: "signal",
    filters: [
      {
        text: 'Buying',
        value: 'High probability Buying opportunity',
      },

      {
        text: 'Selling',
        value: 'High probability Selling opportunity',
      },

    ],
    onFilter: (value, record) => record.signal.startsWith(value),
    filterSearch: true,
    width: '40%',
  },
  {
    title: "Pattern",
    dataIndex: "pattern",
    key: "pattern",
    filters: [
      {
        text: 'Marabazou Bullish',
        value: 'maru_bozu_bullish	',
      },
      {
        text: 'Marabazou Bearish',
        value: 'maru_bozu_bearish',
      },

      {
        text: 'DOJI BULLISH',
        value: 'doji_bullish',
      },

      {
        text: 'DOJI BEARISH',
        value: 'doji_bearish',
      },

      //engulfing_bullish
      {
        text: 'Bullish Engulfing',
        value: 'engulfing_bullish',
      },

      {
        text: 'Bearish Engulfing',
        value: 'engulfing_bearish',
      },

    ],

    onFilter: (value, record) => record.pattern.startsWith(value),
    filterSearch: true,
    width: '40%',
    // sorter: {
    //   compare: (a, b) => (a.signalType < b.signalType ? true : false),
    //   multiple: 3,
    // },
  },

  {
    title: "RSI",
    dataIndex: "rsi",
    key: "rsi",
    render : (text)=> parseFloat(text).toFixed(2),
    sorter: 
     (a, b) => (a.rsi - b.rsi ),
    
    
  },
  // {
  //   title: "Time",
  //   dataIndex: "time",
  //   key: "time",
  // },
  // {
  //   title: "Prev close",
  //   dataIndex: "previous_close",
  //   key: "previous_close",
  // },
  // {
  //   title: "LTP",
  //   dataIndex: "last_price",
  //   key: "last_price",
  //   sorter: {
  //     compare: (a, b) => a.last_price - b.last_price,
  //     multiple: 2,
  //   },
  // },
  // {
  //   title: "% Change",
  //   dataIndex: "change",
  //   key: "change",
  //   sorter: {
  //     compare: (a, b) => Number(a.change) - Number(b.change),
  //     multiple: 1,
  //   },
  // },
  // {
  //   title: "Signal Generated Date",
  //   dataIndex: "signalGeneratedDate",
  //   key: "signalGeneratedDate",
  //   render: (text) => <p>{moment(new Date(text)).format("hh:mm:ss A")}</p>,
  //   sorter: {
  //     compare: (a, b) => a.signalGeneratedDate - b.signalGeneratedDate,
  //     multiple: 1,
  //   },
  // },
];

const SignalsTable = ({ signals }) => {
  // const data = signals.filter(({ instrumentType }) =>
  //   showCe ? instrumentType === "CE" : instrumentType === "PE"
  // );


  const data = signals

  return (
    <div style={{ margin: "10px auto", width: "90vw" }}>
      <Table
        rowKey="tradingSymbol"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default SignalsTable;