import { useContext, useEffect, useState } from "react";
import "./login.scss";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, analytics } from "../../firebase";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../../context/AuthContext"
import { Link } from "react-router-dom";
import styled from "styled-components";
import Input from "../../components/design/Input";
import toast from 'react-simple-toasts';

import { collection, query, where, getDocs,doc, getDoc } from "firebase/firestore";

import { db } from "../../firebase";
import { setUserProperties,logEvent,setUserId } from "firebase/analytics";
const moment = require('moment');
//import { collection, query, where, getDocs } from "firebase/firestore";


const Login = () => {
  
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navitage = useNavigate()

  const {dispatch} = useContext(AuthContext)
  
  const handleLogin = (e)=>  {
    e.preventDefault();
    console.log("entered handlelogin");
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        const uid = userCredential.user.uid;
      //  console.log(uid)


      

        const docRef = doc(db, "users", email.toLowerCase());
        try {
          const docSnap = await getDoc(docRef);
          var userData = docSnap.data();
       //   console.log(userData);

          if(!userData){
            toast("No such user exists",{time : 5000});
            return
          }

          let getTrialEndDate = userData.trialEndDate;
       //   console.log(getTrialEndDate)

          let getUserRegistrationDate = userData.registrationDate;

         let validTrial = checkExceedLimit(getUserRegistrationDate);
       //  console.log(validTrial)


         let isPremium = userData.isPremium_BBC;
         let software = userData.software;


         if(isPremium === "true" && userData.software_BBC){

          let startDate = userData.premium_StartDate_BBC;
          let endDate = userData.premium_EndDate_BBC;
            //logic for end date check
         let access =   checkAccessDate(startDate,endDate)

            if(access){
              

              dispatch({type:"LOGIN", payload:user})
              navitage("/")
            
              //setUserId(email)
              setUserProperties(analytics,{app: "BBC",login : email})
              logEvent(analytics, 'user_info', {
                email: email,
                app: 'BBC',
                login: 'success'
              });

              toast("Logged in  successfully",{time : 2000});

            }else{

              toast("Your subscription expired. Kindly renew to continue accessing tool",{time : 7000});
            }

         }
          else if(validTrial){

           
            dispatch({type:"LOGIN", payload:user})
            navitage("/")
            toast("Logged in  successfully",{time : 2000});
            //setUserId(email)
            setUserProperties(analytics,{app: "BBC",login : email})
            logEvent(analytics, 'user_info', {
              email: email,
              app: 'BBC',
              login: 'success'
            });
            

          }else{

            // dispatch({type:"LOGIN", payload:user})
            // navitage("/")
            toast("Your access period expired",{time : 3000});
           
            setUserProperties(analytics,{app: "BBC",failedLogin : email})
            logEvent(analytics, 'user_info', {
              email: email,
              app: 'BBC',
              login: 'failed'
            });

          }

         

      } catch(error) {
          console.log(error)
          toast(`Error logging in  ${error}`,{time : 3000});
      }
       

     
       




      
      })
      .catch((error) => {
        setError(true);
        toast("Seems your id isn't registered. Kindly contact admin",{time : 2000});
        console.log(error)
      });
  };




  function checkExceedLimit (registrationDate) {
   
    
      const dateLimit = moment(registrationDate, 'DD-MM-YY');
      const now = moment().format('DD-MM-YY')
      const trialExpiryDate = moment(registrationDate,'DD-MM-YY').add(4,'d').format('DD-MM-YY')
    //  console.log(trialExpiryDate)
      if (dateLimit.isValid() &&   (moment(now,'DD-MM-YY')).isBefore(moment(trialExpiryDate,'DD-MM-YY'))) {
          
          console.log(now.toString())
          console.log(trialExpiryDate.toString())
          return true
      }

      return false;
    
  }


  function checkAccessDate (startDate,endDate) {
   
    
    const dateLimit = moment(startDate, 'DD-MM-YY');
    const now = moment().format('DD-MM-YY')
   
    const trialExpiryDate = moment(endDate,'DD-MM-YY')
    console.log(trialExpiryDate)
    if (dateLimit.isValid() &&   (moment(now,'DD-MM-YY')).isSameOrBefore(moment(trialExpiryDate,'DD-MM-YY'))) {
        
        console.log(now.toString())
        console.log(endDate.toString())
        return true
    }

    return false;
  
}

  const routeChange = () =>{ 
    //toast("Now new registration allowed",{time : 5000});
    navitage(`/register`)

  }

  return (

    <div >

    <div className="loginContainer">

   
    <MainContainer>
    <WelcomeText>Welcome</WelcomeText>


    <InputContainer>
      <Input type="email" placeholder="Email" placeholderTextColor="green" onChange={(e) => setEmail(e.target.value)}/>
   
      <Input type="password" placeholder="Password"  onChange={(e) => setPassword(e.target.value)} />
    </InputContainer>
    <ButtonContainer>
      <StyledButton content="Sign in"  type="submit"  onClick={handleLogin}> Sign In</StyledButton>
    </ButtonContainer>
  
    {/* <ForgotPassword>Forgot Password ?</ForgotPassword> */}


   
    <HorizontalRule />

    <LoginWith>Don't have an account with us?</LoginWith>

   
    <ButtonContainer>
   
      <StyledButton content="Sign Up"  onClick ={routeChange} > Sign Up</StyledButton>
      
      </ButtonContainer>


  </MainContainer>

  </div>
  </div>
   
  );
};



const MainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  width: 30vw;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  @media only screen and (max-width: 320px) {
    width: 80vw;
    height: 100vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 80vw;
    height: 100vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    height: 100vh;
  }
  @media only screen and (min-width: 768px) {
    width: 80vw;
    height: 90vh;
  }
  @media only screen and (min-width: 1024px) {
    width: 70vw;
    height: 60vh;
  }
  @media only screen and (min-width: 1280px) {
    width: 30vw;
    height: 90vh;
   
  }

  
`;

const WelcomeText = styled.h2`
  margin: 3rem 0 2rem 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20%;
  width: 100%;

`;





const ButtonContainer = styled.div`
  margin: 1rem 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginWith = styled.h5`
  cursor: pointer;
  
`;

const HorizontalRule = styled.hr`
  width: 90%;
  height: 0.3rem;
  border-radius: 0.8rem;
  border: none;
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  background-color: #ebd0d0;
  margin: 1.5rem 0 1rem 0;
  backdrop-filter: blur(25px);
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 3rem 0;
  width: 80%;
`;

const ForgotPassword = styled.h4`
  cursor: pointer;
`;


const StyledButton = styled.button`
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  width: 65%;
  height: 3rem;
  border: none;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
`;



export default Login;



//ref
//https://github.com/koolkishan/react-glassmorphism-login-form
//https://www.youtube.com/watch?v=xJVPydZNA14