import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";

import TrackChangesSharpIcon from "@mui/icons-material/TrackChangesSharp";

import SellIcon from "@mui/icons-material/Sell";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import BoltIcon from '@mui/icons-material/Bolt';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  // let { dispatch } = useContext(DarkModeContext);
  let { dispatch } = useContext(AuthContext);

  const handleLogout = () => {
    // const { dispatch } = this.props;
    //dispatch = useContext(AuthContext)

    dispatch({ type: "LOGOUT", payload: null });
  };
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Vijay Wealth</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">OPTION BUYING</p>

          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Nifty</span>
            </li>
          </Link>

          <Link to="/banknifty" style={{ textDecoration: "none" }}>
            <li>
              <LocalFireDepartmentIcon className="icon" />
              <span>BNF</span>
            </li>
          </Link>

          <Link to="/finnifty" style={{ textDecoration: "none" }}>
            <li>
              <BoltIcon className="icon" />
              <span>FinNifty</span>
            </li>
          </Link>

          <Link to="/equity-option-buying" style={{ textDecoration: "none" }}>
  <li>
    <PsychologyOutlinedIcon className="icon" />
    <span>Equity</span>
  </li>
</Link>


{/* <Link to="/crude-option-buying" style={{ textDecoration: "none" }}>
            <li>
              <PsychologyOutlinedIcon className="icon" />
              <span>Crude </span>
            </li>
          </Link> */}

          <p className="title">OPTION SELLING</p>

          <Link to="/nifty-option-selling" style={{ textDecoration: "none" }}>
            <li>
              <SellIcon className="icon" />
              <span>Nifty </span>
            </li>
          </Link>

          <Link
            to="/banknifty-option-selling"
            style={{ textDecoration: "none" }}
          >
            <li>
              <AccountBalanceWalletIcon className="icon" />
              <span>BNF</span>
            </li>
          </Link>


          <Link
            to="/finnifty-option-selling"
            style={{ textDecoration: "none" }}
          >
            <li>
              <FlashOnIcon className="icon" />
              <span>FinNifty</span>
            </li>
          </Link>

           <Link to="/equity-option-selling" style={{ textDecoration: "none" }}>
  <li>
    <CreditCardIcon className="icon" />
    <span>Equity </span>
  </li>
</Link> 

{/* <Link to="/crude-option-selling" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Crude </span>
            </li>
          </Link> */}

          {/* <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <li>
            <CreditCardIcon className="icon" />
            <span>Orders</span>
          </li>
          <li>
            <LocalShippingIcon className="icon" />
            <span>Delivery</span>
          </li>
          <p className="title">USEFUL</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li> */}

          {/* <p className="title">Equity</p> */}

          {/* <Link to="/positional" style={{ textDecoration: "none" }}>
            <li>
              <TrackChangesSharpIcon className="icon" />
              <span>Positional</span>
            </li>
          </Link> */}

          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <div onClick={() => handleLogout()}>
            <li>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <ExitToAppIcon className="icon" />

                <span>Logout</span>
              </Link>
            </li>
          </div>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
