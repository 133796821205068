import { Space, Spin } from "antd";
import { useEffect, useReducer, useState,useRef } from "react";
import socketIOClient from "socket.io-client";
import {secondaryDatabase} from "../../firebase"
import {  ref, onValue, get, child} from "firebase/database";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./Home.scss";

import Toolbar from "./Toolbar";
import SignalsTable from "./SignalsTable";





const Positional = () => {
  

   
  const todoRef = secondaryDatabase;
  const refrenceForBBCDB = ref(todoRef, 'rsiData/');
  const [todoList, setTodoList] = useState({ status: "loading", data: null, error: null });

  const socketRef = useRef();
let sahil = [];
  useEffect(() => {
    
//Firebase code
 
const listener = onValue(refrenceForBBCDB, (snapshot) => {
  //const data = snapshot.val();
  const todos = snapshot.val();
  //console.log(todos)

 
        const todoList = [];
      for (let id in todos) {

      //  console.log(todos[id])

        var temp = todos[id];

        for(let x in temp){
         // console.log("\t x:"+ temp[x].scriptname)
          sahil.push(temp[x])
        }
       
        // todoList.push({...todos[id][0]})
        // sahil.push({...todos[id][0]})
       
      }
      console.log("sahil:" + sahil)
    
      setTodoList(sahil);

      console.log("todoLisdt:"+ todoList)
     // setData()
     // setData({ type: "SORT" , payload : sahil.reverse()});

      console.log("*******************************")
  
});


return listener;
  




  }, []);


  if (!todoList) {
    return (
      <Space
        style={{ height: "100vh", width: "100%", justifyContent: "center" }}
        align="center"
        direction="vertical">
        <Spin size="large" />
      </Space>
    );
  }


 
  switch (todoList.status) {
    case "loading":
      return null; // hides component, could also show a placeholder/spinner
    case "error":
      return (
        <div class="error">
          Failed to retrieve data: {todoList.error.message}
        </div>
      );
  }




  return (


    <div className="home">
    <Sidebar />
    <div className="homeContainer">
    {
       todoList && todoList ?   <SignalsTable {...{ signals:todoList }} /> : 
       <h1>This functionality was available as complimentary. Stay tuned on channel to know about it's further details</h1>
     }
      </div>
    </div>

  
  );
};

export default Positional;