import { Space, Spin } from "antd";
import { useEffect, useReducer, useRef } from "react";
import socketIOClient from "socket.io-client";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./Home.scss";
import { SERVER_URI } from "../../constants";
import Toolbar from "./Toolbar";
import SignalsTable from "./SignalsTable";


const SOCKET_ENDPOINT = SERVER_URI;



const NIFTY_OPTIONS_SELLING = () => {
  
  const [data, setData] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_POSSIBLE_MONTH_YEAR_COMBO":
          return { ...state, possibleMonthYearCombo: action.payload };

        case "SET_SELECTED_MONTH_YEAR_COMBO":
          return {
            ...state,
            selectedMonthYearCombo: action.payload,
            signals: [],
          };

        case "COUNT" :
           // console.log("COUNT RECEIVED:" +action.payload)

        return{
    count
 : action.payload
        };

        case "ADD_SIGNAL":
          const newSignals = action.payload;

          newSignals.sort(
            (a, b) => b.signalGeneratedDate - a.signalGeneratedDate
          );

        

         

          return { ...state, signals: [...newSignals, ...state.signals] };

        case "TOGGLE_CE/PE":
          return { ...state, showCe: !state.showCe };

        case "TICK":
         // console.log("GOT TICK");
          const signalsWithLastPrice = [...state.signals];

          action.payload.forEach(({ last_price, instrument_token }) => {
            for (const signalIndex in signalsWithLastPrice) {
              const signal = signalsWithLastPrice[signalIndex];
              if (
                Number(signal.instrument_token) === Number(instrument_token)
              ) {
               // console.log("SIGNAL MATCHED");
                signal.last_price = last_price;
                signal.change = (
                  ((last_price - signal.previous_close) /
                    signal.previous_close) *
                  100
                ).toFixed(2);
                break;
              }
            }
          });


          var temp = signalsWithLastPrice;
          var CE =  temp
         .filter(item => (item.instrumentType === "CE")).length;

        
        var  PE = temp
        .filter(item => (item.instrumentType === "PE")).length;
       


          return { ...state, signals: signalsWithLastPrice,CE,PE };

        default:
          return state;
      }
    },
    {
      possibleMonthYearCombo: null,
      signals: [],
      selectedMonthYearCombo: null,
      showCe: true,
      count: [],
     CE : 0,
     PE :0 
    }
  );

  const socketRef = useRef();

  useEffect(() => {

    console.log("Entered use effect ");
    socketRef.current = socketIOClient(SOCKET_ENDPOINT, {
      transports: ["websocket"],
    });

    const socket = socketRef.current;

    socket.on("initialData", (data) => {
      let filteredData = data.filter((script) => script.name.includes("NIFTY") && !script.name.includes("BANKNIFTY") && !script.name.includes("FINNIFTY") && script.category.includes("sell"))
      setData({ type: "ADD_SIGNAL", payload: filteredData});
      //.filter((script) => script.name.includes("NIFTY") && !script.name.includes("BANKNIFTY") && script.category.includes("sell"))
    });

    socket.on("possibleMonthYearCombo", (data) => {
    //  console.log("GOT POSSIBLE MONTH YEAR COMBO", data);
      setData({ type: "SET_POSSIBLE_MONTH_YEAR_COMBO", payload: data });
    });

    socket.on("selectedMonthYearCombo", (data) => {
     // console.log("GOT SELECTED MONTH YEAR COMBO", data);
      setData({ type: "SET_SELECTED_MONTH_YEAR_COMBO", payload: data });
    });

    socket.on("data", (data) => {

      console.log("GOT DATA", JSON.stringify(data));
      setData({ type: "ADD_SIGNAL", payload: data.filter((script) => script.name.includes("NIFTY") && !script.name.includes("BANKNIFTY")  && !script.name.includes("FINNIFTY")&& script.category.includes("sell"))});
    });


    socket.on("count", (data) => {
      console.log("GOT count", data);
      setData({ type: "COUNT", payload: data });
    });

    socket.on("tick", (ticks) => {
      //console.log("GOT TICK", ticks);
      setData({ type: "TICK", payload: ticks });
    });

    return () => {
      socket.disconnect(true);
    };
  }, []);

  const {
    possibleMonthYearCombo,
    signals,
    selectedMonthYearCombo,
    showCe,
    count,
   CE,
   PE
  } = data;

  if (!possibleMonthYearCombo || !selectedMonthYearCombo || !signals) {
    return (
      <Space
        style={{ height: "100vh", width: "100%", justifyContent: "center" }}
        align="center"
        direction="vertical">
        <Spin size="large" />
      </Space>
    );
  }


  const handleMonthYearComboChange = (val) => {
   // console.log({ val });
    const [year, month] = val.split("-");
    socketRef.current.emit("changeMonthYearCombo", { year, month });
  };

  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
      {/* <Navbar /> */}

     
      <Toolbar
        {...{
          showCe,
          setData,
          selectedMonthYearCombo,
          possibleMonthYearCombo,
          handleMonthYearComboChange,
          CE,
          PE,
          signals //way2 if ce,pe doesnt work
        }}
      />
      <SignalsTable {...{ signals, showCe }} />
      </div>
    </div>
  );
};

export default NIFTY_OPTIONS_SELLING;