import "./chart.scss";
import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  CanvasJSChart
} from "recharts";


import Chart from 'react-apexcharts'





const Charts = ({ aspect, title,todo, open }) => {

  console.log("--------------charts ----------------------")
  console.log(todo)
  let chartCategory = [];
  let ceOI =  []
  let peOI = []
  const min = parseInt(open) -250;
  const max = parseInt(open) + 250
  for (let id in todo) {

     console.log("charts:"+todo[id].strikePrice)
     //todoList.push({ id, ...todos[id] }); 


     var add = inRange(
      parseInt(todo[id].strikePrice),
      parseInt(min),
      parseInt(max)
    );

    if(add){
      chartCategory.push((todo[id].strikePrice))
      ceOI.push((todo[id].callOI))
      peOI.push((todo[id].putOI))
    }
    
  }

  console.log(chartCategory)
  
const [state,setState] = useState({
  options: {
    chart: {
      id: "basic-bar",
      stacked: true,
      //stackType: "100%"
    },
    xaxis: {
      categories: chartCategory
    }
  },
  series: [
    {
      name: "CE",
      data: ceOI
    },
    {
      name: "PE",
      data: peOI
    },
  ]

});


function inRange(x, min, max) {
  //min = min === null ? global.NIFTY_MIN_RANGE : min;
  //max = max === null ? global.NIFTY_MAX_RANGE : max;
  return (x - min) * (x - max) <= 0;
}


  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>

      <div>
            
            <Chart
              options={state.options}
              series={state.series}
              type="bar"
              width= '80%'
            />
            </div>
        {/* <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="OI"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart> */}
      </ResponsiveContainer>
    </div>
  );
};

export default Charts;
