import "./register.scss";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import validator from "validator";

import styled from "styled-components";
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import toast from 'react-simple-toasts';
import { Space, Spin } from "antd";
//import { Button, Spinner } from 'react-bootstrap'



import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


const moment = require('moment');

const New = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [userData, setUserData] =  useState();
  const[loading,setLoading] = useState(null)
  const [per, setPerc] = useState(null);
  const [buttonState,setButtonState] = useState('');
  const navigate = useNavigate()

  
const routeChange = () =>{ 
 
  navigate(`/`)

}




  useEffect(() => {

    


    // var checkDateValidityBefore = moment(todaysDate).isSameOrBefore(registrationEndDate)
    // console.log(checkDateValidityBefore)

    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  console.log(data);

  const handleInput = (e) => {
    //todo

   

    //do validation here
    const id = e.target.id;
    const value = e.target.value;

    

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    
    
    
    try {


      
    if (!validator.isEmail(data.email)) {
      return toast("Enter Valid Email",{time : 3000});
    
    }

    if (String(data.displayName).length < 4) {
      return toast("Enter Valid Name",{time : 3000});
    }
    if (String(data.phoneNumber).length < 9) {
      return toast("Enter Valid Phone Number",{time : 3000});
      
    }
    

    if (String(data.password).length < 6) {
      return toast("Password should have min length of 6 ",{time : 3000});
      
    }
    


    toast("Registartion in progress", {time : 1000});
      if(data.email && data.password && data.displayName && data.phone ){
       


          console.log("All fields added")
          const res = await createUserWithEmailAndPassword(
            auth,
            data.email,
            data.password,
           
          );
    
          var registrationDate = moment(new Date()).format("DD-MM-YY")
          console.log("start:" +registrationDate)
          var registrationEndDate = moment(new Date()).add(4,'d').format("DD-MM-YY")
          console.log("end:"+ registrationEndDate)
    
          await setDoc(doc(db, "users", (data.email).toLowerCase()), {
            ...data,
            timeStamp: serverTimestamp(),
            registrationDate,
            trialEndDate: registrationEndDate,
            software: "BBC"

          });

          inputs = null;
          toast("Registered successfully",{time : 3000});
          setLoading(null)
          //todo temp
          navigate(-1) 

      }else{
        console.log(" fields missing")
        toast("All fields are compulsary", {time : 3000});
        setLoading(null)
      }


    
    } catch (err) {
      setLoading(null)
      console.log(err);
      
      console.log(err.message)
      if(err.message === "Firebase: Error (auth/email-already-in-use).")
        toast(err.message.split("Firebase: Error (auth/")[1], {time : 3000});
      else      
        toast("Something went wrong. Please try again", {time : 3000});
      //email-already-in-use
    }
  };

  
const theme = createTheme();

const handleSubmit = async (event) => {
  event.preventDefault();


  try{
  const data = new FormData(event.currentTarget);
  console.log(data)
 
  let email = data.get('email');
  let password = data.get('password');
  let displayName = data.get('displayName')
  let phone = data.get('phone');
  var registrationDate = moment(new Date()).format("DD-MM-YY")
  console.log("start:" +registrationDate)
  var registrationEndDate = moment(new Date()).add(4,'d').format("DD-MM-YY")
  console.log("end:"+ registrationEndDate)


  console.log(displayName)



  if (String(displayName).length < 4) {
    return toast("Enter Valid Name",{time : 3000});
  }

  if (!validator.isEmail(email)) {
    return toast("Enter Valid Email",{time : 3000});
  
  }

  if (String(password).length < 6) {
    return toast("Password should have min length of 6 ",{time : 3000});
    
  }

  
  if (String(phone).length < 9) {
    return toast("Enter Valid Phone Number",{time : 3000});
    
  }
  

 
  


  toast("Registartion in progress", {time : 1000});

  if(email && password && displayName && phone ){


    const res = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
     
    );
  
   
  
     await setDoc(doc(db, "users", (email).toLowerCase()), {
     
      timeStamp: serverTimestamp(),
     
  
          email:email,
      password:password,
      displayName:displayName,
      phone:phone,
      registrationDate:registrationDate,
      trialEndDate:registrationEndDate,
      payment_BBC:0,
      software:"BBC",
      
  
  
  
    });


    inputs = null;
    toast("Registered successfully",{time : 3000});
    setLoading(null)
    //todo temp
    navigate(-1) 

    
  } else{
    console.log(" fields missing")
    toast("All fields are compulsary", {time : 3000});
    setLoading(null)
  }


} catch (err) {
  setLoading(null)
  console.log(err);
  
  console.log(err.message)
  if(err.message === "Firebase: Error (auth/email-already-in-use).")
    toast(err.message.split("Firebase: Error (auth/")[1], {time : 3000});
  else      
    toast("Something went wrong. Please try again", {time : 3000});
  //email-already-in-use
}

 

  

  // console.log(displayName,email,password, premium_StartDate_BBC)
  // // console.log({
  // //   email: data.get('email'),
  // //   password: data.get('password'),
  // // });

  // //const examcollref = doc(db,'users', id)
  // const docRef = doc(db, "users", useremail.toLowerCase());
  // updateDoc(docRef,{
  //   email:email,
  //   password:password,
  //   displayName:displayName,
  //   phone:phone,
  //   registrationDate:registrationDate,
  //   trialEndDate,trialEndDate,
  //   payment_BBC:payment_BBC,
  //   software_BBC:software_BBC,
  //   isPremium_BBC:isPremium_BBC,
  //   premium_StartDate_BBC:premium_StartDate_BBC,
  //   premium_EndDate_BBC:premium_EndDate_BBC
  // } ).then(response => {
  //   alert("updated")
  //   navigate(-1)

  // }).catch(error =>{
  //   console.log(error.message)
  // })

};


function goBack(){
  navigate(-1)
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        VijayWealthBBC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

  if (!loading == null) {
    return (
      <Space
        style={{ height: "100vh", width: "100%", justifyContent: "center" }}
        align="center"
        direction="vertical">
        <Spin size="large" />
      </Space>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Register user for #BBC
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  autoComplete="off"
                  name="displayName"
                  required
                  fullWidth
                  label = "Name and surname"
                  id="displayName"
                //  defaultValue= { userData ? userData.displayName : "hold" }
                  
                 
                  // onChange = {(e) =>{
                  //   setData({...data,displayName: e.target.value})
                  // }}
                 
                  autoFocus
                 
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="email"
                  name = "email"
                //  defaultValue={userData ? userData.email : "email"}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  required
                  fullWidth
                  id="password"
                  label="password"
                  name="password"
                //  defaultValue={userData ? userData.password : "password"}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                required
                fullWidth
                id="phone"
                label="phone"
                name="phone"
               // defaultValue={userData ? userData.phone : "phone"}
                autoComplete="family-name"
                />
              </Grid>
  
              
  
  
                  <Grid item xs={12}>
                  <TextField
                  required
                  fullWidth
                  id="country"
                  label="country"
                  name = "country"
                  defaultValue="India"

                  />






                  </Grid>
  
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
  
            
            >
              Submit
            </Button>
  
  
            <Button
             
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              
               onClickCapture = {() => navigate(`/`)}
            
            >
              back
            </Button>
            {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
//   return (
//     <div className="new">
//       {/* <Sidebar /> */}
//       <div className="newContainer">
//         {/* <Navbar /> */}
//         <div className="top">
//           <h1>{title}</h1>
//         </div>
//         <div className="bottom">
//           <div className="left">
//             <img
//               src={
//                 file
//                   ? URL.createObjectURL(file)
//                   : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
//               }
//               alt=""
//             />
//           </div>
//           <div className="right">
//             <form onSubmit={handleAdd}>
//               <div className="formInput">
//                 <label htmlFor="file">
//                   Image: <DriveFolderUploadOutlinedIcon className="icon" />
//                 </label>
//                 <input
//                   type="file"
//                   id="file"
//                   onChange={(e) => setFile(e.target.files[0])}
//                   style={{ display: "none" }}
//                 />
//               </div>

//               {inputs.map((input) => (
//                 <div className="formInput" key={input.id}>
//                   <label>{input.label}</label>
//                   <input
//                     id={input.id}
//                     type={input.type}
//                     placeholder={input.placeholder}
//                     onChange={handleInput}
                    
//                   />
//                 </div>
//               ))}


//               <button disabled={per !== null && per < 100 && setLoading(true)} type="submit">


             
//                 Submit Details


                 
          


//               </button>

//               {/* <ProgressButton disabled={per !== null && per < 100} onClick={this.handleAdd} state={this.state.buttonState}>
//           Go!
//         </ProgressButton> */}

              
//             </form>


//           </div>
//         </div>
//         {/* <button  type="submit">
//                 Go Back to login
//               </button>
//                */}

// <ButtonContainer>
//       <StyledButton content="Sign in"  type="submit"   onClick ={routeChange} > Go back to login</StyledButton>
//     </ButtonContainer>
//       </div>
      
      
//     </div>
//   );
};


const ButtonContainer = styled.div`
  margin: 1rem 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  width: 65%;
  height: 3rem;
  border: none;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
`;

export default New;
